import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import { Provider } from 'react-redux';
import store from "./app/store";
import Requiredpath from "./auth/redux/Requiredpath";
// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (

    <React.Fragment>
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route element={<Requiredpath />}>
              {protectedRoutes.map((route, index) => (
                <Route path={route.path} element={route.element} key={index} />
              ))}
            </Route>

            {publicRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              )
            })}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Provider>
      </BrowserRouter>
    </React.Fragment>


  );
}