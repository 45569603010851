import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import Main from "../../layouts/Main";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from 'react-redux';
import { fetchList, fetchMetaTagById ,updatemetaItem,CreateMetaItem} from "../../api/ajishstephen/apiActions";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import feather from 'feather-icons';

export default function Ajishstephen() {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.list.items);
  const metaTag = useSelector((state) => state.list.metaTag);
  // const status = useSelector((state) => state.list.status);
  // const error = useSelector((state) => state.list.error);
  const { status, success, error } = useSelector((state) => state.list);

  useEffect(() => {
      if (status === 'succeeded' && success) {
          Swal.fire({
              title: 'Success!',
              text: success,
              icon: 'success',
              confirmButtonText: 'OK'
          });
      }
      if (status === 'failed' && error) {
          Swal.fire({
              title: 'Error!',
              text: error,
              icon: 'error',
              confirmButtonText: 'OK'
          });
      }
  }, [status, success, error])


  const [skin, setSkin] = useState(localStorage.getItem("skin-mode") ? "dark" : "");
  const [showModal, setShowModal] = useState(false);
  const [currentMetaTagId, setCurrentMetaTagId] = useState(null);

  const hasFetched = useRef(false);

  useEffect(() => {
    feather.replace();

    if (!hasFetched.current && items === null) {
      dispatch(fetchList());
      hasFetched.current = true;
    }

    switchSkin(skin);
  }, [dispatch, items, skin]);

  const switchSkin = (skin) => {
    const btnWhite = document.getElementsByClassName("btn-white");
    const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

    for (const btn of btnWhite) {
      btn.classList.toggle("btn-outline-primary", skin === "dark");
      btn.classList.toggle("btn-white", skin !== "dark");
    }

    for (const btn of btnOutlinePrimary) {
      btn.classList.toggle("btn-outline-primary", skin === "dark");
      btn.classList.toggle("btn-white", skin !== "dark");
    }
  };

  const openCreateModal = () => {
    setCurrentMetaTagId(null);
    setShowModal(true);
  };

  const openEditModal = (id) => {
    setCurrentMetaTagId(id);
    dispatch(fetchMetaTagById(id));
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      page: '',
      page_url: '',
      title: '',
      description: '',
      keywords:'',
      canonical:''
    },
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    }
  });

  useEffect(() => {
    // alert(currentMetaTagId)
    if (metaTag && currentMetaTagId) {

      reset(metaTag);
    } else {

      reset({
        page: '',
        page_url: '',
        title: '',
        description: '',
        keywords:'',
        canonical:''
      });
    }
    feather.replace();
  }, [metaTag, currentMetaTagId, reset]);

  const onSubmit = async (data) => {
    try {
      if (currentMetaTagId) {

        dispatch(updatemetaItem({ currentMetaTagId, data }));
      
        // alert("hi"),
        // await axios.put(`/api/meta-tags/${currentMetaTagId}/`, data);
      } else {
        dispatch(CreateMetaItem({data}))
       
        // await axios.post('/api/meta-tags/', data);
      }
      dispatch(fetchList());
      closeModal();
    } catch (error) {
      console.error('Error saving meta tag', error);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Main />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Ajish Stephen</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                /List
              </li>
            </ol>
            <h4 className="main-title mb-0">Meta Datas</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
            >
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={openCreateModal}
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Add
              <span className="d-none d-sm-inline"> Meta Data</span>
            </Button>
          </div>
        </div>

        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0 pb-3">
                <div>
                  {status === 'loading' && <p>Loading...</p>}
                  {status === 'failed' && <p>Error: {error}</p>}
                  {status === 'succeeded' && Array.isArray(items) && (
                    <Table responsive striped bordered hover>
                      <thead style={{ 'height': '70px', 'textAlign': 'center', 'verticalAlign': 'middle' }}>
                        <tr>
                          <th>Number</th>
                          <th>Website</th>
                          <th>Page Name</th>
                          <th>Title</th>
                          <th>Meta Desc</th>
                          <th>Edit</th>
                          {/* Add more columns as needed */}
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>ajishstephen.com</td>
                            <td>{item.page_name} - {item.page_url}</td>
                            <td>{item.meta_title}</td>
                            <td>{item.meta_description}</td>
                            <td><a style={{ 'cursor': 'pointer' }} onClick={() => openEditModal(item.id)}><i style={{ 'color': 'green' }} data-feather="edit"></i></a></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal show={showModal} onHide={closeModal} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{currentMetaTagId ? 'Edit Meta Data' : 'Add Meta Data'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>


              <Row>
                <Col sm="12" md="6">
                  <Form.Label htmlFor="page" className=" col-form-label">Page</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control-lg"
                    id="page"
                    {...register('page', { required: "Page name is required" })}
                  />
                  {errors.page && <p className="text-danger">{errors.page.message}</p>}
                </Col>




                <Col sm="12" md="6">
                  <Form.Label htmlFor="page_url" className="  col-form-label">Page Url</Form.Label>
                  <Form.Control
                    type="text"
                    className="form-control-lg"
                    id="page_url"
                    {...register('page_url',{ required: "Page name is required" })}
                  />
                </Col>
              </Row>



              


              <Row className="">
                <Col sm="12" md="6">
                  <Form.Label htmlFor="title" className="col-form-label">Title</Form.Label>

                  <Form.Control
                    className="form-control-lg"
                    type="text"
                    id="title"
                    {...register('title', { required: "Title is required" })}
                  />
                  {errors.title && <p className="text-danger">{errors.title.message}</p>}
                </Col>
              </Row>
              <Row className="">
                <Col sm="12" md="12">
                  <Form.Label htmlFor="description" className="col-form-label">Canonical</Form.Label>

                  <Form.Control
                    className="form-control-lg"
                    type="text"
                    id="canonical"
                    {...register('canonical',{ required: "Canonical  is required" })}
                  />
                  {errors.canonical && <p className="text-danger">{errors.canonical.message}</p>}
                </Col>
              </Row>

              <Row className="">
                <Col sm="12" md="12">
                  <Form.Label htmlFor="description" className="col-form-label">Description</Form.Label>

                  <Form.Control
                    as="textarea"
                    rows="3"
                    id="description"
                    {...register('description',{ required: "Description  is required" })}
                  />
                  {errors.description && <p className="text-danger">{errors.description.message}</p>}
                </Col>
              </Row>
              <Row className="">
                <Col sm="12" md="12">
                  <Form.Label htmlFor="keywords" className="col-sm-2 col-form-label">KeyWords</Form.Label>

                  <Form.Control
                    as="textarea"
                    rows="3"
                    id="keywords"
                    {...register('keywords',{ required: "Keywords  is required" })}
                  />
                  {errors.keywords && <p className="text-danger">{errors.keywords.message}</p>}
                </Col>
              </Row>
              <Button variant="primary" type="submit" className="mt-3">
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}



