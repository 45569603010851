import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { loginUser } from "../auth/redux/authActions"
import { useDispatch } from 'react-redux';



export default function Choosetype() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSubmit = async (e) => {
    e.preventDefault();
    const credentials = { email, password };
    let errorMessages = [];

    setErrors('');


    if (!email) {
      setErrors("Please enter your email .");
      return; 
    }
  
    if (!password) {
      setErrors("Please enter your password.");
      return; 
    }
    const actionResult = await dispatch(loginUser(credentials));
    if (loginUser.fulfilled.match(actionResult)) {
      navigate('/');
    } else {
      if (actionResult.error && actionResult.error.message) {
        setErrors(actionResult.payload.message);
      } else {
        console.elogrror('Login failed');
      }

    }
  }

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="5" xl="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Link to="/" className="header-logo mb-5"><img src={'https://res.cloudinary.com/statex/image/upload/v1710526559/statex-crm/logo1_pr9eqp.png'} style={{ width: 180 }} /></Link>
              <Card.Title>Sign In</Card.Title>
              <Card.Text>Welcome back! Please signin to continue.</Card.Text>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={loginSubmit}>
                <div className="mb-4">
                  {errors && <p style={{ color: 'red' }}>{errors}</p>}
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="text" placeholder="Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Password <Link to="">Forgot password?</Link>
                  </Form.Label>
                  <Form.Control type="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <Button type="submit" className="btn-sign">Sign In</Button>

                <div className="divider"><span>or sign in with</span></div>

                <Row className="gx-2">
                  <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
                  <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
                </Row>
              </Form>
            </Card.Body>
            <Card.Footer>
              Don't have an account? <Link to="/signup">Create an Account</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <img src={'https://res.cloudinary.com/statex/image/upload/v1710947917/statex-crm/bg1_nx8zkv.jpg'} className="auth-img" alt="" />
        </Col>
      </Row>
    </div>
  )
}