import { paths } from '../../routes/paths';
import axios from '../../utils/axios';
import { useNavigate } from 'react-router-dom';

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const tokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');
    sessionStorage.removeItem('accessToken');
    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

export const setSession = (accessToken) => {

  if (accessToken) {
 
    const now = new Date();
    // `ttl` is in milliseconds
    // const item = {
    //     value: accessToken,
    //     expiry: now.getTime() + ttl,
    // };
    // localStorage.setItem('new_token', JSON.stringify(item));
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken')
    // sessionStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;

  
  }
};
