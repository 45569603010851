// // actions/apiActions.js
// import axios from 'axios';
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';




// // Async thunk to fetch data
// export const fetchList = createAsyncThunk('list/fetchList', async () => {
//     const response = await axios.get('http://127.0.0.1:8000/get-meta-data-list/'); 
//     return response.data;
// });

// // Async thunk to create a new item
// export const createItem = createAsyncThunk('list/createItem', async (newItem) => {
//     const response = await axios.post('/api/list', newItem); 
//     return response.data;
// });

// const listSlice = createSlice({
//     name: 'list',
//     initialState: {
//         items: [], 
//         status: 'idle',
//         error: null
//     },
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchList.pending, (state) => {
//                 state.status = 'loading';
//             })
//             .addCase(fetchList.fulfilled, (state, action) => {
//                 state.status = 'succeeded';
//                 state.items = action.payload.datas; 
//             })
//             .addCase(fetchList.rejected, (state, action) => {
//                 state.status = 'failed';
//                 state.error = action.error.message;
//             })
//             .addCase(createItem.fulfilled, (state, action) => {
//                 state.items.push(action.payload);
//             });
//     }
// });

// export default listSlice.reducer;



import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const ajish_base_url =process.env.REACT_APP_AJISH_URL

// Async thunk to fetch data
export const fetchList = createAsyncThunk('list/fetchList', async () => {
    
    const response = await axios.get(`${ajish_base_url}/get-meta-data-list/`); // Replace with your API endpoint
    return response.data;
});

export const fetchMetaTagById = createAsyncThunk('list/fetchMetaTagById', async (id) => {
    const response = await axios.get(`${ajish_base_url}/meta-data-details/${id}/`);
    return response.data;
});


// export const fetchMetaTagById = createAsyncThunk('list/meta-data-details' , async(id) => {

//     const response =  await axios.get(`${ajish_base_url}/meta-data-details/${id}`);
//     return response.data
// })


// export const fetchMetaTagById = (id) => async (dispatch) => {
//     dispatch({ type: 'FETCH_META_TAG_REQUEST' });
//     try {
//       const response = await axios.get(`${ajish_base_url}/meta-data-details/${id}/`);
//       dispatch({ type: 'FETCH_META_TAG_SUCCESS', payload: response.data });
//     } catch (error) {
//       dispatch({ type: 'FETCH_META_TAG_FAILURE', error });
//     }
//   };


// export const createmetaItmem = createAsyncThunk('list/createItem', async (newItem) => {
//     const response = await axios.post('/api/list', newItem); 
//     return response.data;
// });


// export const updatemetaItem = createAsyncThunk('list/updateItme' ,async (id,updateitem) => {
//    console.log(updateitem,"ssssssssssssssssssssss")
//     const response =  await axios.put(`${ajish_base_url}/update-meta-data/${id}/` , updateitem);
//     return response.data
// } )

// export const updatemetaItem = createAsyncThunk(
//     'list/updateItem',
//     async ({ currentMetaTagId, data }) => {
//         console.log(data, "ssssssssssssssssssssss");
//         console.log(currentMetaTagId)
//         const response = await axios.put(`${ajish_base_url}/update-meta-data/${currentMetaTagId}/`, data);
//         return response.data;
//     }
// );


export const updatemetaItem = createAsyncThunk(
    'list/updateItem',
    async ({ currentMetaTagId, data }) => {
        const response = await axios.put(`${ajish_base_url}/update-meta-data/${currentMetaTagId}/`, data);
        return response.data;
    }
);


export const CreateMetaItem = createAsyncThunk(
    'list/createItem',
    async ({data}) => {
        console.log(data,"dddddddddddddddddddddddddd");
        const response = await axios.post(`${ajish_base_url}/create-meta-data/`, data);
        return response.data;
    }
);

const listSlice = createSlice({
    name: 'list',
    initialState: {
        items: null,
        status: 'idle',
        error: null,
        success:null,
        metaTag: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload.datas;
            })
            .addCase(fetchList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(fetchMetaTagById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMetaTagById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.metaTag = action.payload;
            })
            .addCase(fetchMetaTagById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            // Handle the update case
            .addCase(updatemetaItem.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.items.findIndex(item => item.id === action.payload.id);
                if (index !== -1) {
                    state.items[index] = action.payload;
                }
            })
            .addCase(updatemetaItem.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(CreateMetaItem .fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.success = action.payload.message;
              })
              .addCase(CreateMetaItem .rejected, (state,action) => {
                  state.status = 'failed';
                  state.error = action.payload.message;
  
              });
  
    }
});

export default listSlice.reducer;