import axiosInstance from "../../utils/axios";
import { endpoints , fetcher} from "../../utils/axios";



export const getCountries = async (query) => {
    try {
      const countriesData = await fetcher(`${endpoints.countries}?search=${query}`);
      console.log(countriesData);
      return countriesData; 
    } catch (error) {
      console.error(error); 
    }
  };
  

  