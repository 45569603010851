import { createSlice } from '@reduxjs/toolkit';
import { loginUser } from './authActions'; 
import { setSession, isValidToken } from './utils';

  const getSessionData = () => {
    const userStr = localStorage.getItem('accessToken');
    if (userStr) {
      // const userData = JSON.parse(userStr);
      const isValid = isValidToken(userStr);
      return isValid ? userStr : null;
    }
    return null;
  };

  const sessionData = getSessionData();
  console.log(sessionData)

  const initialState = {
    user: sessionData ? sessionData.user : null,
    isAuthenticated: !!sessionData,
    error: null,
  };
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: (state) => {
      localStorage.removeItem('user');
      state.user = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {

    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload.error;
      });
  },
});

// Export reducers and actions
export const { logoutUser } = authSlice.actions;
export default authSlice.reducer;
