import React ,{useState,useEffect  } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCountries } from "../api/common/commonapi";
import AsyncSelect from 'react-select/async';

export default function Signup() {
  const [selectedOption, setSelectedOption] = useState(null);

  const loadOptions = (inputValue) => {

    return getCountries(inputValue).then(countriesData =>
      countriesData.map(country => ({
        label: country.name,
        value: country.id,
      }))
    );
  };



  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };
 
  return (
    <div className="page-sign">
      <Card className="card-sign" style={{ width: '75%' }}>
        <Card.Header className="d-flex flex-column  align-items-center mt-5">
          <Link to="/" className="header-logo mb-4"><img src={'https://res.cloudinary.com/statex/image/upload/v1710526559/statex-crm/logo1_pr9eqp.png'} style={{ width: 180 }} /></Link>
          <Card.Title>Sign Up</Card.Title>
          <Card.Text>It's free to signup and only takes a minute.</Card.Text>
        </Card.Header>
        <Card.Body>
        <div className="row">
            <div className="col-md-6 col-sm-12 mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="text" placeholder="Enter your email" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-3">
              <Form.Label>Country</Form.Label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={loadOptions}
                onChange={handleChange}
                value={selectedOption}
              />
              </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your comapany name" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12 mb-3">
              <Form.Label>Phone No.</Form.Label>
              <Form.Control type="text" placeholder="Enter your  phone numbber" />
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Enter your password" />
            </div>
          </div>
          <div className="mb-3">
            <Form.Label>Adress </Form.Label>
            <Form.Control type="text" placeholder="Enter your address" />
          </div>
          <div className="mb-4">
            <small>By clicking <strong>Create Account</strong> below, you agree to our terms of service and privacy statement.</small>
          </div>

          <div className="d-flex flex-column align-items-center">
            <Button variant="primary" className="btn-sign" style={{width:180}}>Create Account</Button>
          </div>
          

          <div className="divider"><span>or sign up using</span></div>

          <Row className="gx-2">
            <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
            <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          Already have an account? <Link to="/signin">Sign In</Link>
        </Card.Footer>
      </Card>
    </div>
  );
}