import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>&copy; 2023. statex. All Rights Reserved.</span>
      <span>Created by: <Link to="http://www.statexcloud.com" target="_blank">statex</Link></span>
    </div>
  )
}