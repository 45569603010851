import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../auth/redux/authSlice';
import listReducer from '../api/ajishstephen/apiActions'


 const store = configureStore({
  reducer: {
    auth: authReducer,
    list: listReducer
  },
});


export default store;