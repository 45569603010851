import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { setSession, isValidToken } from './utils';
import {encryptWithAES} from '../../security/seccurity'

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }, thunkAPI) => {
    try {
      const response = await axios.post('/api/login/', { email, password });
      const data = response.data;
      if (response.status === 200) {
        const { authorisation: { accessToken } = {}, user } = response.data;
        setSession(accessToken);
        // const encpt=encryptWithAES(user.id.toString())
        // alert(encpt)
        // localStorage.setItem('user',encpt);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);

    }
  }
);




export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async (_, thunkAPI) => {
    try {
  
      setSession(null);
      localStorage.removeItem('user');
      return {}; 
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);